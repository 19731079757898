import React, { useState } from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import SectionTitle from "../components/SectionTitles/SectionTitle";

const events = [
	{
		image: "/images/breakfast-event.jpg",
		title: "Breakfast Meeting",
		description: `
            ABOUT OUR LAST BREAKFAST MEETING HELD ON 25TH JANUARY, 2024 AT HYATT/KILIMANJARO HOTEL:

            Much thanks to all CEOs who attended the CEOs' Breakfast Meeting which was held at Hyatt/Kilimanjaro Hotel on 25th January,2024. 
            The Breakfast Meeting started at 7:30 a.m. 

            On the Registration Desk, Ian Blasio and Jestina  Fredrick   who were registering the in-coming CEOs. 
            Presentation of the research paper started by Prof. Duncan Henry Sisya,main Theme was “Going Regional” and the Topic was “Challenges facing Tanzanian Companies’ Going Regional”. 

            The survey report was conducted by Eastern Star Consulting Group Tanzania Limited.The research paper of the survey report covered wide spectrum such as,
            •⁠  ⁠Lack of funding (18%);
            •⁠  ⁠Bureaucracy & prolonged processes (10%);
            •⁠  ⁠Lack of exposure (10%) and more,

            Among all four challenges were discussed 
            •⁠  ⁠Lack of funding; 
            •⁠  ⁠Lack of exposure;
            •⁠  ⁠Limited resources and technology;
            •⁠  ⁠The epidemics-Covid 19. 

            After breakfast all CEOs presented their applicitions regarding the presentation of the research survey report and they were contented.
        `,
		date: "25th January 2024",
	},
];

const Events = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState({});

	const closeModal = () => {
		setIsOpen(false);
	};

	return (
		<React.Fragment>
			<SEO title="Tanzanite roundtable || Events and Gallary" />
			<Header />
			<Breadcrumb
				image="images/dar.jpeg"
				title="Events"
				content="Home"
				contentTwo="Events"
			/>
			<div className={`section section-padding-t90-b100`}>
				<div className="container">
					<SectionTitle
						title="Our Events"
						subTitle="Tanzanite roundtable events."
					/>

					<div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 mt-8">
						{events &&
							events.slice(0, 3).map((data, key) => {
								return (
									<div
										key={key}
										className="col mb-6"
										data-aos="fade-up"
									>
										<div className="blog">
											<div className="thumbnail">
												<div
													onClick={() => {
														setIsOpen(true);
														setData(data);
													}}
													className="image"
												>
													<img
														src={data.image}
														alt="Blog Image"
													/>
												</div>
											</div>
											<div className="info">
												<ul className="meta">
													<li>
														<i className="far fa-calendar"></i>
														{data.date}
													</li>
													<li>
														<i className="far fa-eye"></i>
														{data.view}
													</li>
												</ul>
												<h3 className="title">
													<h2
														onClick={() => {
															setIsOpen(true);
															setData(data);
														}}
													>
														{data.title}
													</h2>
												</h3>
												<button
													onClick={() => {
														setIsOpen(true);
														setData(data);
													}}
													className="btn btn-primary"
												>
													Read More
												</button>
											</div>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</div>
			<CallToAction />
			<Footer />
			<ScrollToTop />
			{isOpen ? (
				<div
					className={`modal fade ${isOpen ? "show" : ""}`}
					style={{ display: isOpen ? "block" : "none", top: "100px" }}
				>
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{data.title}</h5>
								<button
									type="button"
									className="btn"
									aria-label="Close"
									onClick={closeModal}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div
								style={{
									padding: "0 16px",
									overflow: "scroll",
									maxHeight: "60vh",
								}}
							>
								<img
									src={data.image}
									style={{ width: "100%" }}
									alt="Blog Image"
								/>
								<div
									dangerouslySetInnerHTML={{
										__html: data.description,
									}}
								/>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									onClick={closeModal}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};

export default Events;
