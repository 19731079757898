import React from "react";

const MembershipType = () => {
	return (
		<div className="section section-padding-t90">
			<div className="container">
				<div className="mt-8" data-aos="fade-up">
					<div className="section-title-two mb-4">
						<h3 className="title">VIP Membership</h3>
					</div>

					<p>
						This is a kind of membership where in this category
						members will benefit from other platform open forum Plus
						Meeting with policy makers, Top Government stake holders
						includes ministers and presidents, global development
						partners and attending World Investment, Business and
						economics forums each year to represent our organization
						and come back and share with other members. These
						members will be given space to place their business
						logos on our website and a dedicated press release page
						for their companies.
					</p>
				</div>
			</div>
		</div>
	);
};

export default MembershipType;
