import React from "react";

const ScrollToTop = (props) => {
	const stick = true;
	const urlEncodedMessage = encodeURIComponent(
		"Hello, I would like to know more about Tanzanite CEO roundtable."
	);
	const whatsappLink = `https://wa.me/+255 717 109 362?text=${urlEncodedMessage}`;

	return (
		<a
			href={whatsappLink}
			target="_blank"
			rel="noopener noreferrer"
			className={`scroll-top ${stick ? "show" : ""}`}
			{...props}
		>
			<i className="fab fa-whatsapp arrow-top"></i>
			<i className="fab fa-whatsapp arrow-bottom"></i>
		</a>
	);
};

export default ScrollToTop;
