import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle.jsx";
import PillarItem from "../../components/Pillars/PillarItem.jsx";

const pillarData = [
	{
		icon: "/images/svg/partnership.png",
		text: "Partnership",
	},
	{
		icon: "/images/svg/engage.png",
		text: "Engage",
	},
	{
		icon: "/images/svg/ethics.png",
		text: "Ethics",
	},
	{
		icon: "/images/svg/growth.png",
		text: "Growth",
	},
];

const OurPillars = () => {
	return (
		<div className="section section-padding-t90-b100 bg-color-1">
			<div className="container">
				<SectionTitle headingOption="title fz-32" title="Our Four Pillars" />

				<div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 mb-n6">
					{pillarData &&
						pillarData.map((single, key) => {
							return (
								<div
									key={key}
									className="col mb-6"
									data-aos="fade-up"
									data-aos-delay="300"
								>
									<PillarItem
										classOption="box-border"
										data={single}
										key={key}
									/>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default OurPillars;
