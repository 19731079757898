import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import SectionTitle from "../components/SectionTitles/SectionTitle";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Publication = () => {
	const publications = [
		{
			image: "/images/publications/draft-report.png",
			ppt: "/images/publications/Draft Report.pptx",
		},
	];

	return (
		<React.Fragment>
			<SEO title="Tanzanite roundtable || Publication" />
			<Header />
			<Breadcrumb
				image="images/dar.jpeg"
				title="Publications"
				content="Home"
				contentTwo="Publication"
			/>
			<div className="contact-form-section section section-padding-t90-b100 bg-primary-blue">
				<div className="container">
					<div className="row">
						<div className="offset-lg-2 col-lg-8">
							<SectionTitle
								headingOption="fz-32"
								title="Publications"
								subTitle="Tanzanite roundtable publications."
							/>
							<div className="">
								{publications.map((publication, index) => (
									<div
										key={index}
										className="col-md-4 mb-4 mt-8"
									>
										<img
											src={publication.image}
											style={{ cursor: "pointer" }}
											alt={`Publication ${index}`}
											onClick={() => {
												window.open(publication.ppt);
											}}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<CallToAction />
			<Footer />
			<ScrollToTop />
		</React.Fragment>
	);
};

export default Publication;
