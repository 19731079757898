import React from "react";
import PropTypes from "prop-types";

const PillarItem = ({ classOption, data }) => {
	return (
		<div className={`icon-box height-300 text-center ${classOption}`}>
			<div className="icon">
				<img src={data.icon} alt="pillar" />
			</div>
			<div className="content">
				<h3 className="title">{data.text}</h3>
			</div>
		</div>
	);
};

PillarItem.propTypes = {
	classOption: PropTypes.string,
	data: PropTypes.object,
};

export default PillarItem;
